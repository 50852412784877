import React, { useState } from "react";
import { Logo } from "components/logos";
import { Transition } from "@headlessui/react";
import clsx from "clsx";
import FeatureFlyout from "../FeatureFlyout";
import { ChevronDownIcon } from "@heroicons/react/solid";

export default function Header({ className = "" }) {
  const [open, setOpen] = useState(false);
  const [featuresOpen, setFeaturesOpen] = useState(false);
  return (
    <>
      <FeatureFlyout open={featuresOpen} />
      <nav
        className={clsx(
          "relative max-w-screen-xl mx-auto flex items-center justify-between px-4 sm:px-6 z-50",
          className,
        )}
      >
        <div className="flex items-center flex-1">
          <div className="flex items-center justify-between w-full md:w-auto">
            <a href="/" aria-label="Home">
              <Logo className="h-8 w-auto sm:h-10" />
            </a>
            <div className="-mr-2 flex items-center md:hidden">
              <button
                onClick={() => setOpen(!open)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                id="main-menu"
                aria-label="Main menu"
                aria-haspopup="true"
              >
                <svg
                  className="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="hidden md:flex md:ml-10 md:justify-center w-full">
            <a
              onClick={() => setFeaturesOpen(!featuresOpen)}
              className="cursor-pointer inline-flex items-center ml-10 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
            >
              Features
              <ChevronDownIcon className="ml-1 text-gray-400 group-hover:text-gray-500 h-5 w-5" />
            </a>
            <a
              href="/pricing"
              className="ml-10 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
            >
              Pricing
            </a>
            <a
              href="/blog/"
              className="hidden lg:flex ml-10 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
            >
              Blog
            </a>
            <a
              href="/affiliates/"
              className="hidden lg:flex ml-10 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
            >
              Refer & Earn 30%
            </a>
          </div>
        </div>
        <div className="hidden md:block text-right">
          <span className="inline-flex rounded-md shadow-md  mr-4">
            <span className="inline-flex rounded-md shadow-sm">
              <a
                href="/signup"
                className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700 transition ease-in-out duration-150"
              >
                Start for Free
              </a>
            </span>
          </span>
          <span className="inline-flex rounded-md shadow-md">
            <span className="inline-flex rounded-md ring-1 ring-black ring-opacity-5">
              <a
                href="/admin"
                className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50 focus:outline-none focus:border-indigo-300 focus:ring-indigo transition duration-150 ease-in-out"
              >
                Log in
              </a>
            </span>
          </span>
        </div>
      </nav>
      <Transition
        show={open}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100 "
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
        appear={true}
      >
        <div className="z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-md">
            <div
              className="rounded-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="main-menu"
            >
              <div className="px-5 pt-4 flex items-center justify-between">
                <div></div>
                <div className="-mr-2">
                  <button
                    onClick={() => setOpen(!open)}
                    type="button"
                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                    aria-label="Close menu"
                  >
                    <svg
                      className="h-6 w-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="px-2 pt-2 pb-3">
                <a
                  href="#features"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                  role="menuitem"
                >
                  Features
                </a>
                <a
                  href="/pricing"
                  className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                  role="menuitem"
                >
                  Pricing
                </a>
                <a
                  href="/payment-processors/"
                  className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                  role="menuitem"
                >
                  Payment Gateways
                </a>
                <a
                  href="#faq"
                  className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                  role="menuitem"
                >
                  FAQ
                </a>
                <a
                  href="/blog"
                  className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                  role="menuitem"
                >
                  Blog
                </a>
                <a
                  href="https://help.checkoutjoy.com"
                  className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                  role="menuitem"
                >
                  Docs
                </a>
              </div>

              <div>
                <a
                  href="/signup"
                  className="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100 hover:text-indigo-700 focus:outline-none focus:bg-gray-100 focus:text-indigo-700 transition duration-150 ease-in-out"
                >
                  Sign up
                </a>
                <a
                  href="/login"
                  className="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100 hover:text-indigo-700 focus:outline-none focus:bg-gray-100 focus:text-indigo-700 transition duration-150 ease-in-out"
                  role="menuitem"
                >
                  Log in
                </a>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
}
