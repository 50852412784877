import React, { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";

import {
  CheckIcon,
  CursorClickIcon,
  DesktopComputerIcon,
  ReceiptTaxIcon,
  ShieldCheckIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";

const features = [
  {
    name: "Storefronts",
    href: "/features/storefronts/",
    icon: ShoppingCartIcon,
  },
  {
    name: "Checkout Pages",
    href: "/features/checkout-pages/",
    icon: CursorClickIcon,
  },
  {
    name: "Embedded Checkout",
    href: "/features/checkout-widgets/",
    icon: ShoppingBagIcon,
  },
  {
    name: "Sales Tax & Invoicing",
    href: "/features/sales-tax-invoicing/",
    icon: ReceiptTaxIcon,
  },
  {
    name: "Affiliate System",
    href: "/#affiliate-platform",
    icon: ShieldCheckIcon,
  },
  {
    name: "Customer Portal",
    href: "/features/customer-portal/",
    icon: UserGroupIcon,
  },
  {
    name: "Digital Products",
    href: "/features/digital-products/",
    icon: DesktopComputerIcon,
  },
];
const integrations = [
  { name: "Kajabi", href: "/integrations/kajabi/", icon: CheckIcon },
  { name: "Thinkific", href: "/integrations/thinkific/", icon: CheckIcon },
  { name: "Kartra", href: "/integrations/kartra/", icon: CheckIcon },
  { name: "Teachable", href: "/integrations/teachable/", icon: CheckIcon },
  { name: "Quaderno", href: "/integrations/quaderno/", icon: CheckIcon },
  {
    name: "Zapier",
    href: "https://zapier.com/apps/checkoutjoy/integrations",
    icon: CheckIcon,
  },
];

export default function FeatureFlyout({ open }) {
  const [recentPosts, setRecentPosts] = useState<any>([]);
  useEffect(() => {
    fetch(`/api/blog/latest/`)
      .then((rsp) => rsp.json())
      .then(setRecentPosts)
      .catch(console.log);
  }, []);

  return (
    <div className="relative isolate z-50 shadow">
      <Transition
        as={Fragment}
        show={open}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 -translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-1"
      >
        <div className="absolute inset-x-0 top-0 -z-10 bg-white pt-36 shadow-lg ring-1 ring-gray-900/5 -m-12">
          <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-6 py-10 lg:grid-cols-2 lg:px-8">
            <div className="grid grid-cols-2 gap-x-6 sm:gap-x-8">
              <div>
                <h3 className="text-sm font-medium leading-6 text-gray-500">
                  Features
                </h3>
                <div className="mt-6 flow-root">
                  <div className="-my-2">
                    {features.map((item: any) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="flex gap-x-4 py-2 text-sm font-semibold leading-6 text-gray-900"
                      >
                        <item.icon
                          className="h-6 w-6 flex-none text-gray-400"
                          aria-hidden="true"
                        />
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <h3 className="text-sm font-medium leading-6 text-gray-500">
                  Integrations
                </h3>
                <div className="mt-6 flow-root">
                  <div className="-my-2">
                    {integrations.map((item: any) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="flex gap-x-4 py-2 text-sm font-semibold leading-6 text-gray-900"
                      >
                        <item.icon
                          className="h-6 w-6 flex-none text-gray-400"
                          aria-hidden="true"
                        />
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-10 sm:gap-8 lg:grid-cols-2">
              <h3 className="sr-only">Recent posts</h3>
              {recentPosts.map((post) => (
                <article
                  key={post.id}
                  className="relative isolate flex max-w-2xl flex-col gap-x-8 gap-y-6 sm:flex-row sm:items-start lg:flex-col lg:items-stretch"
                >
                  <div className="relative flex-none">
                    <img
                      className="aspect-[2/1] w-full rounded-lg bg-gray-100 object-cover sm:aspect-[16/9] sm:h-32 lg:h-auto"
                      src={post.imageUrl}
                      alt=""
                    />
                    <div className="absolute inset-0 rounded-lg ring-1 ring-inset ring-gray-900/10" />
                  </div>
                  <div>
                    <div className="flex items-center gap-x-4">
                      <time
                        dateTime={post.datetime}
                        className="text-sm leading-6 text-gray-600"
                      >
                        {post.date}
                      </time>
                      <a
                        href={post.category.href}
                        className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 text-xs font-medium text-gray-600 hover:bg-gray-100"
                      >
                        {post.category.title}
                      </a>
                    </div>
                    <h4 className="mt-2 text-sm font-semibold leading-6 text-gray-900">
                      <a href={post.href}>
                        <span className="absolute inset-0" />
                        {post.title}
                      </a>
                    </h4>
                    <p className="mt-2 text-sm leading-6 text-gray-600">
                      {post.description}
                    </p>
                  </div>
                </article>
              ))}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}
