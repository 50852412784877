import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import notifications from "utils/notifications";
import { AuthContext } from "contexts/auth/auth.provider";
import * as Yup from "yup";
import BlockUi from "react-block-ui";
import { withApollo } from "utils/apollo";

function DarkFooter() {
  const [loading, setLoading] = useState(false);
  const { viewerCountry } = useContext(AuthContext);

  const { getFieldProps, handleSubmit } = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: onSubscribe,
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Invalid email").required("Email is required"),
    }),
  });

  function onSubscribe({ email }) {
    setLoading(true);
    fetch(`/api/newsletter/`, {
      method: "post",
      body: JSON.stringify({
        email,
        fields: {
          country: viewerCountry,
        },
        sourceUrl: window.location.href,
        tags: ["Newsletter"],
      }),
    })
      .then(() => {
        notifications.success(
          `Thank you, please check your email to confirm the subscription.`,
          {
            duration: 10000,
          },
        );
      })
      .catch((e) => notifications.error(e.message))
      .finally(() => setLoading(false));
  }

  return (
    <div className="bg-gray-800">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div className="mt-12 md:mt-0">
                <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                  Platforms
                </h4>
                <ul className="mt-4">
                  <li>
                    <a
                      href="/thinkific"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Thinkific
                    </a>
                  </li>
                  <li className="mt-4">
                    <a
                      target="_blank"
                      href="/kajabi"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Kajabi
                    </a>
                  </li>
                  <li className="mt-4">
                    <a
                      href="/kartra"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Kartra
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                  Support
                </h4>
                <ul className="mt-4">
                  <li>
                    <a
                      href="/pricing"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Pricing
                    </a>
                  </li>
                  <li className="mt-4">
                    <a
                      target="_blank"
                      href="https://help.checkoutjoy.com/"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Documentation
                    </a>
                  </li>
                  <li className="mt-4">
                    <a
                      href="/custom-checkout-pages"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Checkout Pages
                    </a>
                  </li>
                  <li className="mt-4">
                    <a
                      href="/payment-processors/"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Supported Gateways
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                  Company
                </h4>
                <ul className="mt-4">
                  <li>
                    <a
                      href="/contact"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Contact
                    </a>
                  </li>
                  <li className="mt-4">
                    <a
                      href="/blog"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Blog
                    </a>
                  </li>
                  <li className="mt-4">
                    <a
                      href="/affiliates/"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Affiliates
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                  Legal
                </h4>
                <ul className="mt-4">
                  <li className="mt-4">
                    <a
                      href="/privacy"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li className="mt-4">
                    <a
                      href="/terms"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Terms of Service
                    </a>
                  </li>
                  <li className="mt-4">
                    <a
                      href="/refund-policy"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Refund Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-8 xl:mt-0">
            <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
              Subscribe to our newsletter
            </h4>
            <p className="mt-4 text-gray-300 text-base leading-6">
              No spam, unsubscribe anytime
            </p>
            <form onSubmit={handleSubmit} className="mt-4 sm:flex sm:max-w-md">
              <input
                aria-label="Email address"
                {...getFieldProps("email")}
                className="appearance-none w-full px-5 py-3 border border-transparent text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 transition duration-150 ease-in-out"
                placeholder="Enter your email"
              />
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <BlockUi
                  tag={"button"}
                  blocking={loading}
                  type="submit"
                  className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:bg-indigo-400 transition duration-150 ease-in-out"
                >
                  Subscribe
                </BlockUi>
              </div>
            </form>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex md:order-2">
            <a
              href="https://www.facebook.com/checkoutjoy"
              className="text-gray-400 hover:text-gray-300"
            >
              <span className="sr-only">Facebook</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                />
              </svg>
            </a>

            <a
              href="https://www.youtube.com/channel/UCScr4m2AIoyHRoXGIADGpUQ/y"
              className="ml-6 text-gray-400 hover:text-gray-300"
            >
              <span className="sr-only">Youtube</span>

              <svg
                className="h-6 w-6"
                x="0px"
                y="0px"
                viewBox="0 0 158 110"
                enableBackground="new 0 0 158 110"
                xmlSpace="preserve"
              >
                <path
                  fill="currentColor"
                  d="M154.4,17.5c-1.8-6.7-7.1-12-13.9-13.8C128.2,0.5,79,0.5,79,0.5s-48.3-0.2-60.6,3c-6.8,1.8-13.3,7.3-15.1,14
	C0,29.7,0.3,55,0.3,55S0,80.3,3.3,92.5c1.8,6.7,8.4,12.2,15.1,14c12.3,3.3,60.6,3,60.6,3s48.3,0.2,60.6-3c6.8-1.8,13.1-7.3,14.9-14
	c3.3-12.1,3.3-37.5,3.3-37.5S157.7,29.7,154.4,17.5z M63.9,79.2V30.8L103.2,55L63.9,79.2z"
                />
              </svg>
            </a>
          </div>
          <p className="mt-8 text-base leading-6 text-gray-400 md:mt-0 md:order-1">
            © {new Date().getFullYear()} CheckoutJoy (Pty) Ltd. All rights
            reserved.
          </p>
        </div>
        <div className="mt-8 flex justify-center max-w-3xl mx-auto ">
          <p className="text-base leading-6 text-gray-400 md:mt-0 md:order-1 text-center">
            CheckoutJoy is an all-in-one e-commerce platform for selling digital
            products. Our features include sales tax & invoicing, multiple
            currencies, subscription management, prebuilt storefronts, checkout
            pages and checkout widgets, and an affiliate system.
          </p>
        </div>
      </div>
    </div>
  );
}

export default withApollo(DarkFooter);